#sq-cvv {
  background-color: rgba(0,0,0,0.05);
  width: 90px;
  margin: 2px;
  border-radius: 4px;
}

#sq-expiration-date {
  background-color: rgba(0,0,0,0.05);
  margin: 2px;
  width: 90px;
  border-radius: 4px;
}

.container {
  /* width: 425px; */
  margin: 0 auto;
}

#sq-postal-code {
  text-align: center;
  background-color: rgba(0,0,0,0.05);
  margin: 2px;
  border-radius: 4px;
  color: black !important;
}

#sq-ccbox {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  flex-wrap: wrap;
}
#sq-ccbox > input {
  background-color: rgba(0,0,0,0.05) !important;
  max-width: calc(100% - 4px);
  margin-left: 2px;
  color: black;
}
  
#sq-card-number {
  background-color: rgba(0,0,0,0.05);
  max-width: calc(100% - 8px);
  margin: 4px;
  border-radius: 4px;
  color: black;
}

#cc-field-wrapper {
  margin: 0;
  width: 100%;
}

#cc-field-wrapper > input {
  background-color: rgba(0,0,0,0.05) !important;
  width: calc(100% - 40px);
  margin-left: 2px;
  color: black;
}

button.button-credit-card {
  display: block;
  line-height: 1.25em;
  vertical-align: middle;
  height: 45px;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  margin: 5px auto;
  font-size: 22px;
  font-weight: 600;
  background-color: #000;
  color: #fff;
}


#form-container {
  width: 100%;
}

/* Customize the Apple Pay on the Web button */
#sq-apple-pay {
  width: 100%;
  /* margin: 24px 0 16px 0; */
  background-image: url(https://docs.connect.squareup.com/assets/docs/sqpaymentform/Apple_Pay_Mark_RGB_SMALL_052318-a40c688402e8a6684ee6938a380ba825e15163872bae3b9909f70ac028a9b780.png);
  background-color: black;
  background-size: 110%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  cursor: pointer;
}

input::placeholder {
  color: #000;
}

.sq-input--focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.sq-input--error {
  box-shadow: inset 0px 0px 0px 3px rgba(255, 0, 0, 0.5);
}
